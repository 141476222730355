import { render, staticRenderFns } from "./TaskViewLoader.vue?vue&type=template&id=49488952&scoped=true&"
import script from "./TaskViewLoader.vue?vue&type=script&lang=js&"
export * from "./TaskViewLoader.vue?vue&type=script&lang=js&"
import style0 from "./TaskViewLoader.vue?vue&type=style&index=0&id=49488952&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49488952",
  null
  
)

export default component.exports