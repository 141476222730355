<template>
  <div class="task-view-modal-loader">
    <div class="task-view-modal-loader__header">
      <div class="task-view-modal-loader__rectangle"></div>

      <div
        v-for="item in 2"
        :key="item"
        class="task-view-modal-loader__square"
      ></div>
    </div>

    <div class="task-view-modal-loader__body">
      <div class="task-view-modal-loader__body-interface">
        <div
          v-for="item in 6"
          :key="item"
          class="task-view-modal-loader__rectangle"
        ></div>
      </div>

      <div class="task-view-modal-loader__body-settings">
        <div
          v-for="item in 3"
          :key="item"
          class="task-view-modal-loader__rectangle"
        ></div>
      </div>

      <div class="task-view-modal-loader__body-description">
        <div
          v-for="item in 4"
          :key="item"
          class="task-view-modal-loader__rectangle"
        ></div>
      </div>

      <div class="task-view-modal-loader__body-history">
        <div class="task-view-modal-loader__square"></div>
        <div class="task-view-modal-loader__rectangle"></div>
        <div class="task-view-modal-loader__square"></div>
        <div class="task-view-modal-loader__rectangle"></div>
      </div>

      <div class="task-view-modal-loader__body-comment">
        <div class="task-view-modal-loader__body-comment-header">
          <div class="task-view-modal-loader__circle"></div>

          <div
            v-for="item in 2"
            :key="item"
            class="task-view-modal-loader__rectangle"
          ></div>
        </div>

        <div class="task-view-modal-loader__body-comment-body">
           <div
            v-for="item in 3"
            :key="item"
            class="task-view-modal-loader__rectangle"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaskViewLoader',
}
</script>

<style lang="scss" scoped>
.task-view-modal-loader {
  display: grid;
  grid-template-rows: max-content 1fr;
  height: 100%;
  transition: opacity .2s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .4;
    background: linear-gradient(to right, transparent, #FFF, transparent);
    animation: loading 1.3s cubic-bezier(.5, 1, 1, 1) infinite;

    @include dark-theme {
      background: linear-gradient(to right, transparent, $darkThemeBgOne, transparent);
    }
  }
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.task-view-modal-loader__header {
  display: grid;
  grid-template-columns: 152px max-content 1fr;
  grid-column-gap: 10px;
  justify-items: end;
  align-items: center;
  padding: 13px 14px 13px 14px;
  height: 60px;
  border-bottom: 1px solid $borderLight;

  @include dark-theme {
    border-color: $darkThemeBgThree;
  }
}

.task-view-modal-loader__rectangle {
  height: 100%;
  width: 100%;
  background-color: #ECECEC;
  border-radius: 6px;

  .task-view-modal-loader__body-settings & {
    &:first-of-type {
      grid-row: span 2;
    }
  }

  .task-view-modal-loader__body-description & {
    &:first-of-type {
      grid-row: span 3;
    }

    &:nth-of-type(2) {
      max-width: 420px;
    }

    &:nth-of-type(3) {
      max-width: 496px;
    }

    &:nth-of-type(4) {
      max-width: 440px;
    }
  }

  .task-view-modal-loader__body-history & {
    &:nth-of-type(2) {
      max-width: 160px;
    }

    &:nth-of-type(4) {
      max-width: 120px;
    }
  }

  .task-view-modal-loader__body-comment-header & {
    height: 12px;
    border-radius: 4px;
  }

  .task-view-modal-loader__body-comment-body & {
    height: 8px;

    &:first-of-type {
      max-width: 192px;
    }

    &:nth-of-type(2) {
      max-width: 257px;
    }

    &:last-of-type {
      max-width: 257px;
    }
  }

  @include dark-theme {
    background-color: $darkThemeBgTwo;
  }
}

.task-view-modal-loader__square {
  height: 22px;
  width: 22px;
  background-color: #ECECEC;
  border-radius: 4px;

  @include dark-theme {
    background-color: $darkThemeBgTwo;
  }
}

.task-view-modal-loader__circle {
  margin-right: 4px;
  height: 24px;
  width: 24px;
  background-color: #ECECEC;
  border-radius: 50%;

  @include dark-theme {
    background-color: $darkThemeBgTwo;
  }
}

.task-view-modal-loader__body {
  display: grid;
  grid-template-rows: repeat(4, max-content) 1fr;
  align-items: flex-end;
}

.task-view-modal-loader__body-interface {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding: 14px 14px 24px 14px;
  height: 116px;
}

.task-view-modal-loader__body-settings {
  display: grid;
  grid-template-columns: 22px 1fr;
  grid-template-rows: 40px 90px;
  grid-column-gap: 16px;
  grid-row-gap: 7px;
  padding: 0 14px 24px 14px;
  height: 161px;
}

.task-view-modal-loader__body-description {
  display: grid;
  grid-template-columns: 22px 1fr;
  grid-template-rows: repeat(3, 34px);
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  padding: 0 14px 28px 14px;
  height: 146px;
}

.task-view-modal-loader__body-history {
  display: grid;
  grid-template-columns: 22px 1fr;
  grid-template-rows: repeat(2, 22px);
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  padding: 0 14px;
  height: 52px;
}

.task-view-modal-loader__body-comment-header {
  display: grid;
  grid-template-columns: max-content 50px 74px;
  grid-column-gap: 4px;
  align-items: center;
  padding: 16px 14px 8px 14px;
  border-top: 1px solid $borderLight;

  @include dark-theme {
    border-color: $darkThemeBgThree;
  }
}

.task-view-modal-loader__body-comment-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 14px 16px 14px;
  height: 48px;
  max-width: 481px;
}
</style>
